.scroll::-webkit-scrollbar {
  width: 20px;

}

.scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 0.5rem
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border: 6px solid transparent;
  background-clip: content-box;
  border-radius: 0.7rem
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 20px;
}

.shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}



.green {
  background-image: -webkit-linear-gradient(top, #13fB04 0%, #58e343 50%, #ADED99 100%);
}

.gray {
  background-image: -webkit-linear-gradient(top, #d3d3d3 0%, #dfdfdf 50%, #dfdfdf 100%);
}


.led {
  background-color: #dfdfdf;
}

.led:after {
  display: block;
  content: '';
  margin-left: 10%;
  margin-right: 10%;
  width: 40%;
  height: 30%;
  -webkit-border-top-right-radius: 50% 50%;
  -webkit-border-top-left-radius: 50% 50%;
  -webkit-border-bottom-right-radius: 50% 50%;
  -webkit-border-bottom-left-radius: 50% 50%;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4) 0%, rgb(255, 255, 255) 100%);
}